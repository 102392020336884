import React, { Suspense, useState, useEffect } from 'react';
import './services.scss';
import images from '../../../src/constants/images';
import { useLanguage } from '../../context/LanguageContext';

// Lazy load video component
const LazyVideo = ({ src }) => (
    <Suspense fallback={<div>Loading video...</div>}>
        <video src={src} autoPlay loop muted playsInline />
    </Suspense>
);

const ServiceCard = (props) => {
    return (
        <button className={props.className} onClick={props.onClick}>
            <img className="service__icon" loading="lazy" src={props.image} alt={props.title} />
            <img className="service__blur" loading="lazy" src={images.bgBlur} alt="Blur" />
            {props.language === 'ar' ? <h2 dir='rtl'>{props.title}</h2> : <h2>{props.title}</h2>}
        </button>
    );
};

const Services = ({ servicesData }) => {
    const [selectedService, setSelectedService] = useState(servicesData[0] || null);
    const { language } = useLanguage();

    const handleServiceChange = (serviceId) => {
        const selected = servicesData.find(service => service.id === serviceId);
        setSelectedService(selected);
    };

    return (
        <section className="services section container">
            {language === 'ar' ? (
                <div className='ar'>
                    <h1>خدماتنا</h1>
                    <p>حلول الذكاء الاصطناعي التي تعمل على تطوير عملياتك وتعزيز الإنتاجية</p>
                </div>
            ) : (
                <div>
                    <h1>Our Services</h1>
                    <p>Here are our AI solutions services that optimize your operations and boost productivity:</p>
                </div>
            )}

            <div className="services__collection-center ">
                {selectedService && (
                    <>
                        <div className="VidIcons">
                            {/* Lazy load video */}
                            <LazyVideo src={selectedService.animation} />
                            <img className="VidIcons__blur" loading="lazy" src={images.bgBlur} alt="Blur" />
                        </div>
                        {language === 'ar' ? (
                            <div dir='rtl' className="CardInfo ar">
                                <h2>{selectedService.title_ar}</h2>
                                <p>{selectedService.description_ar}</p>
                            </div>
                        ) : (
                            <div className="CardInfo">
                                <h2>{selectedService.title}</h2>
                                <p>{selectedService.description}</p>
                            </div>
                        )}
                        <img className="services__pedestal" loading="lazy" src={images.pedestal} alt="Pedestal" />
                    </>
                )}
            </div>

            <div className="services__collection ">
                <div className="services__collection-left ">
                    {servicesData.slice(0, Math.ceil(servicesData.length / 2)).map(service => (
                        language === 'ar' ? (
                            <ServiceCard
                                language='ar'
                                className="service__card ar"
                                key={service.id}
                                image={service.logo}
                                title={service.title_ar}
                                onClick={() => handleServiceChange(service.id)}
                            />
                        ) : (
                            <ServiceCard
                                language='en'
                                className="service__card"
                                key={service.id}
                                image={service.logo}
                                title={service.title}
                                onClick={() => handleServiceChange(service.id)}
                            />
                        )
                    ))}
                </div>
                <div className="services__collection-right ">
                    {servicesData.slice(Math.ceil(servicesData.length / 2)).map(service => (
                        language === 'ar' ? (
                            <ServiceCard
                                language='ar'
                                className="service__card ar"
                                key={service.id}
                                image={service.logo}
                                title={service.title_ar}
                                onClick={() => handleServiceChange(service.id)}
                            />
                        ) : (
                            <ServiceCard
                                language='en'
                                className="service__card"
                                key={service.id}
                                image={service.logo}
                                title={service.title}
                                onClick={() => handleServiceChange(service.id)}
                            />
                        )
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Services;
