// import React, { useState } from 'react';
// import { useLanguage } from '../../context/LanguageContext';
// import { images } from '../../constants';
// import './contactus.scss';

// const Contactus = ({ contactInfoData }) => {
//     const [formData, setFormData] = useState({
//         full_name: '',
//         phone: '',
//         email: '',
//         message: ''
//     });
//     const { language } = useLanguage();

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData(prevFormData => ({
//             ...prevFormData,
//             [name]: value
//         }));
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
        
//         if (!formData.full_name.trim() || !formData.phone.trim() || !formData.email.trim() || !formData.message.trim()) {
//             alert('All fields are required.');
//             return;
//         }

//         const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
//         if (!emailRegex.test(formData.email)) {
//             alert('Please enter a valid email address.');
//             return;
//         }

//         const phoneRegex = /^\d{8,15}$/;
//         if (!phoneRegex.test(formData.phone)) {
//             alert('Please enter a valid phone number with 8 to 15 digits.');
//             return;
//         }

//         fetch('/api/message/', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(formData),
//         })
//         .then(response => response.json())
//         .then(data => {
//             alert('Message sent successfully!');
//             setFormData({
//                 full_name: '',
//                 phone: '',
//                 email: '',
//                 message: ''
//             });
//         })
//         .catch((error) => {
//             console.error('Error:', error);
//             alert('An error occurred while sending the message.');
//         });
//     };

//     return (
//         <div>
//             {language === 'ar' ? 
//                 <div dir='rtl' className="Contact__top ar">
//                     <h1> للتواصل والإستفسارات</h1>
//                 </div>
//                 :
//                 <div className="Contact__top">
//                     <h1>Contact Us</h1>
//                 </div>
//             }

//             <div className="Contact__collection container section">
//                 <div className="Contact__infocollection">
//                     {contactInfoData.map((info, index) => (
//                         language === 'ar' ? 
//                             <div key={index} className="Contact__info-section ar">
//                                 <img className="Contact__img" src={info.image} alt={info.title} />
//                                 <img className="Contact__blur" src={images.bgBlur} alt="Blur" />
//                                 <h2>{info.header_ar}</h2>
//                                 <p >{info.content_ar}</p>
//                             </div>
//                             :
//                             <div key={index} className="Contact__info-section">
//                                 <img className="Contact__img" src={info.image} alt={info.title} />
//                                 <img className="Contact__blur" src={images.bgBlur} alt="Blur" />
//                                 <h2>{info.header}</h2>
//                                 <p>{info.content}</p>
//                             </div>
//                     ))}
//                 </div>

//                 {language === 'ar' ?
//                     <div dir='rtl' className="Contact__form ar">
//                         <form onSubmit={handleSubmit}>
                            
//                             <input className="Contact__name ar" type="text" placeholder="الاسم الكامل" value={formData.full_name} onChange={handleChange} name="full_name" />
//                             <input className="Contact__no ar" type="number" placeholder="رقم الهاتف" value={formData.phone} onChange={handleChange} name="phone" />
//                             <input className="Contact__email ar" type="email" placeholder=" البريد الإلكتروني" value={formData.email} onChange={handleChange} name="email" />
//                             <textarea className="Contact__msg ar" type="text" placeholder="ما هو استفسارك" value={formData.message} onChange={handleChange} name="message" />
//                             <button className="Contact__send">
//                                 <h2>إرسال</h2>
//                                 <img src={images.arrowLeft} alt="Arrow" />
//                             </button>
//                         </form>
//                     </div>
//                     :
//                     <div className="Contact__form">
//                         <form onSubmit={handleSubmit}>
        
//                             <input className="Contact__name" type="text" placeholder="Full name" value={formData.full_name} onChange={handleChange} name="full_name" />
//                             <input className="Contact__no" type="number" placeholder="Phone number" value={formData.phone} onChange={handleChange} name="phone" />
//                             <input className="Contact__email" type="email" placeholder="Email Address" value={formData.email} onChange={handleChange} name="email" />
//                             <textarea className="Contact__msg" type="text" placeholder="Tell us about your project." value={formData.message} onChange={handleChange} name="message" />
//                             <button className="Contact__send">
//                                 <h2>Send </h2>
//                                 <img src={images.arrowRight} alt="Arrow" />
//                             </button>
//                         </form>
//                     </div>
//                 }
//             </div>
//         </div>
//     );
// };

// export default Contactus;
import React, { useState } from 'react';
import { useLanguage } from '../../context/LanguageContext';
import { images } from '../../constants';
import './contactus.scss';

const Contactus = () => {
    const [formData, setFormData] = useState({
        full_name: '',
        phone: '',
        email: '',
        message: ''
    });
    const { language } = useLanguage();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (!formData.full_name.trim() || !formData.phone.trim() || !formData.email.trim() || !formData.message.trim()) {
            alert('All fields are required.');
            return;
        }

        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
        if (!emailRegex.test(formData.email)) {
            alert('Please enter a valid email address.');
            return;
        }

        const phoneRegex = /^\d{8,15}$/;
        if (!phoneRegex.test(formData.phone)) {
            alert('Please enter a valid phone number with 8 to 15 digits.');
            return;
        }

        // Simulating the form submission
        alert('Message sent successfully!');
        setFormData({
            full_name: '',
            phone: '',
            email: '',
            message: ''
        });
    };

    return (
        <div>
            {language === 'ar' ? 
                <div dir='rtl' className="Contact__top ar">
                    <h1>للتواصل والإستفسارات</h1>
                </div>
                :
                <div className="Contact__top">
                    <h1>Contact Us</h1>
                </div>
            }

            <div className="Contact__collection container section">
                <div className="Contact__infocollection">
                    {language === 'ar' ? (
                        <>
                            <div className="Contact__info-section ar">
                                <img className="Contact__img" src={images.location} alt="Location" />
                                <img className="Contact__blur" src={images.bgBlur} alt="Blur" />
                                <h2>موقعنا</h2>
                                <p>طريق رقم 5712، جو، البحرين</p>
                            </div>
                            <div className="Contact__info-section ar">
                                <img className="Contact__img" src={images.mail} alt="Email" />
                                <img className="Contact__blur" src={images.bgBlur} alt="Blur" />
                                <h2>البريد الإلكتروني</h2>
                                <p>info@nairdc.bh</p>
                            </div>
                            <div className="Contact__info-section ar">
                                <img className="Contact__img" src={images.phone} alt="Phone" />
                                <img className="Contact__blur" src={images.bgBlur} alt="Blur" />
                                <h2>اتصل بنا</h2>
                                <p>+973 1711 1152</p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="Contact__info-section">
                                <img className="Contact__img" src={images.location} alt="Location" />
                                <img className="Contact__blur" src={images.bgBlur} alt="Blur" />
                                <h2>Location</h2>
                                <p>Road No. 5712, Jaww, Bahrain</p>
                            </div>
                            <div className="Contact__info-section">
                                <img className="Contact__img" src={images.mail} alt="Email" />
                                <img className="Contact__blur" src={images.bgBlur} alt="Blur" />
                                <h2>Email</h2>
                                <p>info@nairdc.bh</p>
                            </div>
                            <div className="Contact__info-section">
                                <img className="Contact__img" src={images.phone} alt="Phone" />
                                <img className="Contact__blur" src={images.bgBlur} alt="Blur" />
                                <h2>Contact Us</h2>
                                <p>+973 1711 1152</p>
                            </div>
                        </>
                    )}
                </div>

                {language === 'ar' ? (
                    <div dir='rtl' className="Contact__form ar">
                        <form onSubmit={handleSubmit}>
                            <input className="Contact__name ar" type="text" placeholder="الاسم الكامل" value={formData.full_name} onChange={handleChange} name="full_name" />
                            <input className="Contact__no ar" type="number" placeholder="رقم الهاتف" value={formData.phone} onChange={handleChange} name="phone" />
                            <input className="Contact__email ar" type="email" placeholder="البريد الإلكتروني" value={formData.email} onChange={handleChange} name="email" />
                            <textarea className="Contact__msg ar" type="text" placeholder="ما هو استفسارك" value={formData.message} onChange={handleChange} name="message" />
                            <button className="Contact__send">
                                <h2>إرسال</h2>
                                <img src={images.arrowLeft} alt="Arrow" />
                            </button>
                        </form>
                    </div>
                ) : (
                    <div className="Contact__form">
                        <form onSubmit={handleSubmit}>
                            <input className="Contact__name" type="text" placeholder="Full name" value={formData.full_name} onChange={handleChange} name="full_name" />
                            <input className="Contact__no" type="number" placeholder="Phone number" value={formData.phone} onChange={handleChange} name="phone" />
                            <input className="Contact__email" type="email" placeholder="Email Address" value={formData.email} onChange={handleChange} name="email" />
                            <textarea className="Contact__msg" type="text" placeholder="Tell us about your project." value={formData.message} onChange={handleChange} name="message" />
                            <button className="Contact__send">
                                <h2>Send</h2>
                                <img src={images.arrowRight} alt="Arrow" />
                            </button>
                        </form>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Contactus;
