export const fetchData = async () => {
    try {
        // Make all requests concurrently
        const [
            landingPageResponse,
            mediaFilesResponse,
            whyUsResponse,
            timelineResponse,
            backgroundResponse,
            aboutSectionsResponse,
            skillsResponse,
            servicesResponse,
            partnersResponse,
            contactInfoResponse,
        ] = await Promise.all([
            fetch('/api/landing-page/'),
            fetch('/api/media-files/'),
            fetch('/api/why_us/'),
            fetch('/api/timeline/'),
            fetch('/api/background/'),
            fetch('/api/about-sections/'),
            fetch('/api/skills/'),
            fetch('/api/services/'),
            fetch('/api/partners/'),
            fetch('/api/info/'),
        ]);

        // Parse JSON concurrently as well
        const [
            landingPages,
            mediaFilesData,
            whyUsData,
            timelineData,
            backgroundData,
            aboutSectionsData,
            skillsData,
            servicesData,
            partnersData,
            contactInfoData,
        ] = await Promise.all([
            landingPageResponse.json(),
            mediaFilesResponse.json(),
            whyUsResponse.json(),
            timelineResponse.json(),
            backgroundResponse.json(),
            aboutSectionsResponse.json(),
            skillsResponse.json(),
            servicesResponse.json(),
            partnersResponse.json(),
            contactInfoResponse.json(),
        ]);

        return {
            landingPages,
            mediaFiles: mediaFilesData.media_files,
            whyUsData,
            timelineData,
            backgroundData,
            aboutSectionsData,
            skillsData,
            servicesData,
            partnersData,
            contactInfoData,
        };
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};
