// Logos
import logo from '../assets/logo.webp';
import logoWhite from '../assets/logo-white.webp';

import instagramIcon from '../assets/instagram-svgrepo-com.svg';
import linkedInIcon from '../assets/linkedin-svgrepo-com.svg';
import xIcon from '../assets/X_logo_2023.svg';


// Misc
import arrowLeft from '../assets/arrow-left.webp';
import arrowRight from '../assets/arrow-right.webp';
import elethree from '../assets/Circle png.webp';


import bgBlur from "../assets/blur-bg.webp";
import circleIcon from '../assets/circle-icon.png';
import pedestal from '../assets/pedestal.webp';
import location from '../assets/location-icon.webp';
import mail from '../assets/mail-icon.webp';
import phone from '../assets/phone-icon.webp';


export default {

    logo,logoWhite,  
    
    arrowLeft, arrowRight, bgBlur, circleIcon, pedestal, location, mail,phone,
    
    elethree, instagramIcon, linkedInIcon, xIcon,
};
