import React, { Suspense, useState } from 'react';
import { useLanguage } from '../../context/LanguageContext';
import './successstories.scss';

const Spline = React.lazy(() => import('@splinetool/react-spline'));

const Successstories = () => {
  const [splines, setSplines] = useState([]);
  const { language } = useLanguage();

  return (
    <div className='success section container2'>
      <h1>{language === 'ar' ? 'قصص نجاحنا' : 'Our Success Stories'}</h1>

      <div className="spline__container">
        <Suspense fallback={<div>Loading 3D Scene...</div>}>
          {language === 'ar' ? (
            <Spline className="Spline_Screens" scene="https://prod.spline.design/x7dzNXf754LbrhVh/scene.splinecode" />
          ) : (
            <Spline className="Spline_Screens" scene="https://prod.spline.design/bjz0PNfUkDJ1xnAU/scene.splinecode" />
          )}
        </Suspense>
      </div>
    </div>
  );
}

export default Successstories;
