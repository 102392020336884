// Skills.jsx
import React from 'react';
import Slider from "react-slick";
import { useLanguage } from '../../context/LanguageContext';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './skills.scss';

const Skills = ({ skillsData }) => {
    const settings = {
        arrows: false,
        infinite: true,
        slidesToShow: 1, // Display one image at a time
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 6000, // Changed from 5000 to 3000 for 3 seconds
        speed: 1000,
        pauseOnHover: false,
    };

    const { language } = useLanguage();

    return (
        <div className='Skills'>
            {language === 'ar' ?
                <h1>مهاراتنا الفنية</h1>
                :
                <h1>Our Technical Skills</h1>
            }

            <div className="skills_collection">
                <Slider {...settings}>
                    {skillsData.map(skill => (
                        <div key={skill.id} className="slide">
                            <img src={skill.logo} alt={skill.name} />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default Skills;
