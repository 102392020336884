// import React from 'react'

// import { images } from '../../constants';
// import './footer.scss';
// import { useLanguage } from '../../context/LanguageContext';

// const Footer = ({contactInfoData}) => {

//   const { language } = useLanguage();

//   return (
//     <div className="Footer">

//       <div className='Footer__left'>
//       <div className="Footer__logo">
//     <img src={images.logo} />
//         </div>
//         <hr/>
//         <div className="Footer__socials">

// <a target="_blank" rel="noopener noreferrer" href=" https://www.linkedin.com/company/nairdc/">
// <img src={images.linkedInIcon} />
// </a>

//   <a target="_blank" rel="noopener noreferrer" href=" https://www.instagram.com/NAIRDC_BH/">
//   <img src={images.instagramIcon} />
//   </a>

//   <a target="_blank" rel="noopener noreferrer" href=" https://www.x.com/nairdc_bh/">
//   <img src={images.xIcon} />
//   </a>

// </div>
// </div>

//       {/* <div className='Footer__center'>
//         <div className='Footer__nav1'>
//           <h2>Content</h2>
//           <hr />
//           <ul>
//             <li>Skills</li>
//             <li>Services</li>
//           </ul>
//         </div>
//         <div className='Footer__nav2'>
//           <h2> Information</h2>
//           <hr />
//           <ul>
//           <li>Partners</li>
//           <li>Timeline</li>
//           <li>Success Stories</li>
//           <li>Why us</li>
//           </ul>
//         </div>

//       </div> */}
//       {
//       language ==='ar' ?
//       <div className="Footer__contact ar"> 
//       <h2>تفاصيل الاتصال</h2>
//         <hr />  
//         {contactInfoData.map((info, index) => (
//           <div key={index} className="Footer__contact-sections">
//             <img src={info.image} alt={info.title} />
//             <p dir='rtl'>{info.content_ar}</p>
//           </div>
//         ))}
//         </div>
      
      
//       :<div className="Footer__contact"> 
//         <h2>Contact Details</h2>
//         <hr />  
//         {contactInfoData.map((info, index) => (
//         <div key={index} className="Footer__contact-sections">
//           <img src={info.image} alt={info.title} />
//           <p>{info.content}</p>
//         </div>
//         ))}
      
//       </div>
// }

//       </div>
    
//   )
// }

// export default Footer;

import React from 'react'

import { images } from '../../constants';
import './footer.scss';
import { useLanguage } from '../../context/LanguageContext';

const Footer = () => {
  const { language } = useLanguage();

  return (
    <div className="Footer">

      <div className='Footer__left'>
      <div className="Footer__logo">
          <img src={images.logo} alt="Logo" />
        </div>
        <hr />
        <div className="Footer__socials">
          <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/nairdc/">
            <img src={images.linkedInIcon} alt="LinkedIn" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/NAIRDC_BH/">
            <img src={images.instagramIcon} alt="Instagram" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.x.com/nairdc_bh/">
            <img src={images.xIcon} alt="X" />
          </a>
        </div>
      </div>

      {
        language === 'ar' ? (
          <div className="Footer__contact ar">
            <h2>تفاصيل الاتصال</h2>
            <hr />
            <div className="Footer__contact-sections">
              <img src={images.location} alt="Location" />
              <p dir='rtl'>طريق رقم 5712، جو، البحرين</p>
            </div>
            <div className="Footer__contact-sections">
              <img src={images.mail} alt="Email" />
              <p dir='rtl'>info@nairdc.bh</p>
            </div>
            <div className="Footer__contact-sections">
              <img src={images.phone} alt="Phone" />
              <p dir='rtl'>+973 1711 1152</p>
            </div>
          </div>
        ) : (
          <div className="Footer__contact">
            <h2>Contact Details</h2>
            <hr />
            <div className="Footer__contact-sections">
              <img src={images.location} alt="Location" />
              <p>Road No. 5712, Jaww, Bahrain</p>
            </div>
            <div className="Footer__contact-sections">
              <img src={images.mail} alt="Email" />
              <p>info@nairdc.bh</p>
            </div>
            <div className="Footer__contact-sections">
              <img src={images.phone} alt="Phone" />
              <p>+973 1711 1152</p>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default Footer;
