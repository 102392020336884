import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Navbar, Footer } from './container';
import { Home, Loading } from './pages';
import { LanguageProvider } from './context/LanguageContext';
import './App.scss';
import { fetchData } from './fetchData'; // Import the data fetching function

const App = () => {
    const [loading, setLoading] = useState(true); // Global loading state
    const [appData, setAppData] = useState(null); // State to hold fetched data
    const [preloadedVideo, setPreloadedVideo] = useState(null); // State to hold preloaded video URL
    const videoCache = {};

    const preloadFile = (src) => {
        if (videoCache[src]) {
            return Promise.resolve(videoCache[src]); // Return cached blob URL if available
        }

        return new Promise((resolve, reject) => {
            fetch(src)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`Failed to preload ${src}`);
                    }
                    return response.blob();
                })
                .then((blob) => {
                    const blobUrl = URL.createObjectURL(blob);
                    videoCache[src] = blobUrl; // Cache the blob URL
                    resolve(blobUrl);
                })
                .catch((error) => reject(error));
        });
    };

    useEffect(() => {
        const loadAppData = async () => {
            try {
                const data = await fetchData();
                setAppData(data);

                // Preload only the first video
                const firstPage = data.landingPages[0];
                const firstVideo = firstPage.animation; // Default animation
                const firstVideoAr = firstPage.animation_ar; // Arabic animation (if needed)

                const preloadedVideoUrl = await preloadFile(firstVideo);
                const preloadedVideoArUrl = await preloadFile(firstVideoAr);

                setPreloadedVideo({
                    default: preloadedVideoUrl,
                    ar: preloadedVideoArUrl,
                });

                setLoading(false);
            } catch (error) {
                console.error('Error loading app data:', error);
                setLoading(false);
            }
        };

        loadAppData();
    }, []);

    if (loading) {
        return <Loading />; // Display loading spinner if data is still being fetched
    }

    return (
        <LanguageProvider>
            <Router>
                <div id="home" className="App">
                    <Navbar />
                    <Suspense fallback={<Loading />}>
                        <Routes>
                            <Route
                                exact
                                path="/"
                                element={<Home appData={appData} preloadedVideo={preloadedVideo} />}
                            />
                            {/* Other routes can go here */}
                        </Routes>
                    </Suspense>
                    <Footer contactInfoData={appData?.contactInfoData} />
                </div>
            </Router>
        </LanguageProvider>
    );
};

export default App;
