import Lottie from 'lottie-react';
import nairdcLoading from '../../assets/Nairdc Loading.json';
import './Loading.scss' 
const Loading = ({ progress }) => {
  return (
    
    <div className='loading'>
      <Lottie animationData={nairdcLoading} />
      {progress !== undefined && <p>Loading... {progress}%</p>}
    </div>
 
  )
};

export default Loading;
