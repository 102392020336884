import React from 'react';
import { Hero, About, Services, Whyus, Partners, Contactus, Successstories, Skills } from '../../container';

const Home = ({ appData, preloadedVideo }) => {
    if (!appData) {
        return <div>Loading...</div>; // Display a fallback while data is loading
    }

    return (
        <>
            <div id="Top" />
            <div id="home" />
            <Hero
                landingPages={appData.landingPages}
                preloadedVideo={preloadedVideo} // Pass the preloaded video
            />
            <div id="About Us" />
            <hr className="HrBreak" />
            <About
                timelineData={appData.timelineData}
                backgroundData={appData.backgroundData}
                aboutSectionsData={appData.aboutSectionsData}
            />
            <div id="Services" />
            <hr className="HrBreak" />
            <Services servicesData={appData.servicesData} />
            <div id="Skills" />
            <hr className="HrBreak" />
            <Skills skillsData={appData.skillsData} />
            <div id="Partners" />
            <hr className="HrBreak" />
            <Partners partnersData={appData.partnersData} />
            <div id="Success Stories" />
            <hr className="HrBreak" />
            <Successstories />
            <div id="Why Us" />
            <hr className="HrBreak" />
            <Whyus whyUsData={appData.whyUsData} />
            <div id="Contact Us" />
            <hr className="HrBreak" />
            <Contactus contactInfoData={appData.contactInfoData} />
        </>
    );
};

export default Home;
